<template>

      <div class="ispinner ispinner-large">
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
        <div class="ispinner-blade"></div>
      </div>
</template>

<script>
export default {
    name: "Loader",
}

</script>