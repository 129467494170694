<template>
    <h2>User kepek</h2>
    <button @click="$root.unloadModalComponent">Bezar</button>
</template>

<script>
export default {
    name: "UserImageViewer",
    props: ["componentData"],

}

</script>