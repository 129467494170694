<template>
  <router-view/>
  <ModalComponentLoaderVue v-if="componentName" :componentName="componentName"></ModalComponentLoaderVue>

</template>

<script>
import ModalComponentLoaderVue from './components/ModalComponentLoader.vue';
  export default {
    name: "App",
    components: {
      ModalComponentLoaderVue
    },
    data() {
      return {
        componentName: null,
      }
    },
    mounted() {
      console.log(this.$refs)
    },  
    methods: {
      loadingModalComponent(componentName) {
          this.componentName = componentName
      },
      unloadModalComponent() {
        this.componentName = null
      },
    }

  }



</script>


<style lang="scss">
@import "../src/views/css/main";
@import "../src/views/css/modal";
@import "../src/views/css/loader";
</style>
