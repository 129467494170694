<template>

    <h2>Ban okok</h2>

</template>

<script>
export default {
    name: "ChoiceBanReason",
    props: ["componentData"],
}

</script>