<template>
    <div class="choice-messages-wrapper">
    <h2>Uzenetek</h2>
    <button>Bezar</button>
    <div class="choices-messages">
        <button>Automata uzenet 1.</button>
        <button>Automata uzenet 2.</button>
        <button>Automata uzenet 3.</button>
        <button>Automata uzenet 4.</button>
        <button>Automata uzenet 5.</button>
        <button>Automata uzenet 6.</button>
        <button>Automata uzenet 7.</button>
        <button>Automata uzenet 8.</button>
        <button>Automata uzenet 9.</button>
        <button>Automata uzenet 10.</button>
    </div>
</div>
</template>

<script>

export default {
    name: "ChoiceSendMessage",
    props: ["componentData"],

}
</script>