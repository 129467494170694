<template>
    <div @click="$root.unloadModalComponent" class="overlay">
        <div class="container" v-if="modalContent">
            <ChoiceBanReasonVue v-if="view.loading.ChoiceBanReasonVue" :componentData="componentData"></ChoiceBanReasonVue>
            <ChoiceSendMessageVue v-if="view.loading.ChoiceSendMessageVue" :componentData="componentData"></ChoiceSendMessageVue>
            <UserImageViewer v-if="view.loading.UserImageViewer" :componentData="componentData"></UserImageViewer>
        </div>
        <Loader v-if="view.loading.Loader" :componentData="componentData"></Loader>
    </div>
</template>


<script>
    import ChoiceBanReasonVue from './ChoiceBanReason.vue';
    import ChoiceSendMessageVue from './ChoiceSendMessage.vue';
    import UserImageViewer from './UserImageViewer.vue';
    import Loader from "./loader/Loader.vue"

    export default {
        name: "ModalComponentLoader",
        props:["componentName"],
        components: {
            ChoiceBanReasonVue,
            ChoiceSendMessageVue,
            UserImageViewer,
            Loader
        },
        data() {
            return {
                componentData: null,
                view: {
                    loading: {
                        ChoiceBanReasonVue: false,
                        ChoiceSendMessageVue: false,
                        UserImageViewer: false,
                        Loader: false
                    }
                }
            }
        },
        computed: {
            modalContent() {
                if (this.view.loading.Loader) {
                    return false
                }
                return this.view.loading.ChoiceBanReasonVue || this.view.loading.ChoiceSendMessageVue || this.view.loading.UserImageViewer
            }
        },
        watch: {
            loadingComponentName() {
            }
        },
        mounted() {
            this.loadingComponent()

        },
        methods: {


            defaultState() {
                this.view.loading.ChoiceBanReasonVue = false
                this.view.loading.ChoiceSendMessageVue = false
                this.view.loading.UserImageViewer = false
                this.view.loading.Loader = false
            },
            loadingComponent() {
                this.defaultState()
                this.view.loading[this.componentName] = true
            },
           
        }
        
    }

</script>